import React from 'react';
import { Card } from '../Cards';
import { Container, GridContent, SubHeading, Heading, CarouselCard } from './styles';
import { SliderWrapper } from '../SliderWrapper';
import type { ICardItem as ICard } from '../Cards/types';
import { Slider } from '../SliderVanilla';

export interface ITitles {
  heading: string;
  subHeading: string;
}

export interface ISubcategory {
  titles?: ITitles;
  cardData?: ICardItem[];
  subcategoryCardVariation?:
    | 'product'
    | 'object'
    | 'recipe'
    | 'white'
    | 'WideSmall'
    | 'content'
    | 'article'
    | 'articleImage'
    | 'subcategory'
    | 'tipsAndTricks';
  copyDictionary?: Record<string, string>;
  trackingEvent?: (data?: any, position?: number) => void;
}

export interface ICardItem extends ICard {
  link?: {
    url: string;
    displayName: string;
    openInNewTab: boolean;
  };
}

export const Subcategory = ({
  titles,
  cardData,
  subcategoryCardVariation,
  copyDictionary,
  trackingEvent,
}: ISubcategory) => {
  const hasCarousel = cardData && cardData.length >= 4;

  return (
    <Container>
      <GridContent data-test="subcategory-grid-content">
        {titles?.subHeading && (
          <SubHeading tag="p" type="lead">
            {titles?.subHeading}
          </SubHeading>
        )}
        {titles?.heading && (
          <Heading tag="h2" type="sm">
            {titles?.heading}
          </Heading>
        )}
      </GridContent>
      {hasCarousel ? (
        <SliderWrapper
          data-test="subcategory-slider-wrapper"
          className="sub-category pl-4 lg:pl-10"
        >
          <Slider isFullWidth={false} data-test="subcategory-slider" sideSpacesOnDesktop>
            {cardData?.map((item: ICardItem, index: number) => (
              <CarouselCard key={item.link?.url ?? `subcategory-slider-${index}`}>
                <Card
                  data-test={`subcategory-item-${item.id}`}
                  data={item}
                  cardVariation={subcategoryCardVariation || 'subcategory'}
                  link={item.link}
                  cardType="mediumSmall"
                  height="360px"
                  copyDictionary={copyDictionary}
                  trackingEvent={trackingEvent}
                />
              </CarouselCard>
            ))}
          </Slider>
        </SliderWrapper>
      ) : (
        <GridContent className="grid grid-flow-row md:grid-flow-col gap-8 w-[80%] mx-auto">
          {cardData?.map((item: ICardItem, index: number) => (
            <Card
              key={item.link?.url ?? `subcategory-item-${index}`}
              data-test={`subcategory-item-${item.id}`}
              data={item}
              cardVariation={subcategoryCardVariation || 'subcategory'}
              link={item.link}
              cardType="mediumSmall"
              height="360px"
              copyDictionary={copyDictionary}
              trackingEvent={trackingEvent}
              position={index + 1}
            />
          ))}
        </GridContent>
      )}
    </Container>
  );
};
